import React, { Fragment, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Col, Container, Row } from "reactstrap";

import "./Product.scss";
import style from "./style.module.scss";
import SEO from "../../Components/SEO";

import ItemCard from "../../Components/ItemCard";
import "../../styles/style.scss";

const ChronicCareManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <SEO page="chronicComplexCareManagement" />
      <Header active="our-program" css={"spring-wood"} />
      <Container fluid className="bg-color-spring-wood">
        <div className="container pt-md-5 pb-lg-5">
          <Row>
            <Col md={12} className="pb-lg-3 pb-md-3">
              <h1>Chronic/Complex Care Management (CCM)</h1>
            </Col>
          </Row>
        </div>
      </Container>
      <div className={`${style.section} ${style.section_1}`}>
        <div className={style.section_inner}>
          <div className={style.data_box}>
            <div className={`${style.data_item} ${style.section_1_bg}`}>
              <ItemCard
                heading={"CCM at Avenue"}
                imageUrl={require("../../images/img3.png")}
              >
                <p>
                  Avenue’s Chronic/Complex Care Management (CCM) is a program
                  designed for patients with two or more chronic conditions
                  expected to last 12 months or more or place them at
                  significant risk of death, acute exacerbation/decompensation,
                  or functional decline.
                </p>
                <p>
                  The most common chronic conditions we support are: HTN,
                  Diabetes, CHF, Hyperlipidemia, COPD, Obesity, Depression,
                  Cancer, Osteoarthritis and Dementia. We can design programs
                  for other CCM conditions to meet the needs of your practice.
                </p>
              </ItemCard>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.section}`}>
        <div className={style.heading_box}>
          <div data-container>
            <div data-heading={"h2"}>Avenue’s CCM services provide</div>
          </div>
        </div>
        <div className={style.section_inner}>
          <div className={style.data_box}>
            <div
              className={`${style.data_item} ${style.section_2} ${style.section_2_bg}`}
            >
              <ItemCard imageUrl={require("../../images/img4.png")} isTheme2>
                <ul data-list>
                  <li>
                    Comprehensive assessments and person-
                    <br />
                    centered care plans
                  </li>
                  <li>
                    Patient education (disease process, medication, community
                    resources)
                  </li>
                  <li>Preventive service reminders</li>
                  <li>Transition of care management</li>
                  <li>
                    RN continuity for optimal patient and caregiver engagement
                    and experience
                  </li>
                  <li>
                    Care coordination and information exchange with Specialists
                    and other providers
                  </li>
                </ul>
              </ItemCard>
            </div>
            <div
              className={`${style.data_item} ${style.data_item2} ${style.section_3_bg}`}
            >
              <ItemCard imageUrl={require("../../images/img5.png")}>
                <ul data-list>
                  <li>
                    Support using motivational interviewing techniques to affect
                    lasting health behavior change
                  </li>
                  <li>Transition of care management</li>
                  <li>24/7 patient access to care and health information</li>
                  <li>
                    Provide patients and or caregivers with copy of the care
                    plan
                  </li>
                  <li>Comprehensive billing reports for practice</li>
                  <li>Patient advocacy</li>
                </ul>
              </ItemCard>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.section} ${style.section_3}`}>
        <div className={style.heading_box}>
          <div data-container>
            <div data-heading={"h2"}>
              Other Avenue Health Coach Activities / Coordination / Referrals:
            </div>
          </div>
        </div>
        <div className={style.section_inner}>
          <div className={style.data_box}>
            <div className={`${style.data_item} ${style.section_4_bg}`}>
              <ItemCard imageUrl={require("../../images/img6.png")} isTheme2>
                <ul data-list>
                  <li>
                    Prescription Assistance, Medication Assistance Programs and
                    Co-Pay Assistance Programs
                  </li>
                  <li>Transportation coordination</li>
                  <li>Preventive service reminders</li>
                  <li>Advance Care Planning</li>
                  <li>
                    Community/SDOH resources -good insecurity, utility
                    assistance, housing assistance
                  </li>
                </ul>
              </ItemCard>
            </div>
            <div
              className={`${style.data_item} ${style.data_item2} ${style.section_5_bg}`}
            >
              <ItemCard imageUrl={require("../../images/img7.png")}>
                <ul data-list>
                  <li>PHQ9 Assessments</li>
                  <li>Dietician, specialists, nutritional Programs</li>
                  <li>Transition of care management</li>
                  <li>Medicaid Assistance/Social Worker</li>
                  <li>Senior well-being and senior day program</li>
                </ul>
                <p>
                  <div className={style.note_text}>
                    While CCM services are covered by many insurance plans, some
                    plans may require a small co-insurance.
                  </div>
                </p>
              </ItemCard>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default ChronicCareManagement;
