import React from "react";

import style from "./style.module.scss";

function ItemCard({
  imageUrl,
  heading,
  children,
  isTheme2,
  isTheme3,
  isTheme4,
}) {
  return (
    <div className={style.card_main}>
      <div data-container>
        <div
          className={`${style.card_full} ${isTheme2 && style.theme2} ${
            isTheme3 && style.theme3
          } ${isTheme4 && style.theme4}`}
          data-card-design
        >
          <div className={style.line_design}></div>
          <div className={style.line_design2}></div>
          <div className={style.card}>
            <div className={style.left}>
              <div className={style.img_box} data-img-box>
                <div className={style.img}>
                  <img src={imageUrl} alt={heading} />
                </div>
              </div>
            </div>
            <div className={style.right}>
              <div className={style.info}>
                <div className={style.heading_box}>
                  <div data-heading={"h2"}>{heading}</div>
                </div>
                <div data-content>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemCard;
