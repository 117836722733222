import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ page }) => {
  const title = "Avenue Health";
  const base = "https://www.avenue.healthcare";
  const description =
    "Avenue is a bolt-on service line extension for healthcare. We enable managed remote-patient monitoring, virtual care, and telehealth services for multiple patient populations.";

  const seoProps = {
    home: {
      description,
      title: title,
      keywords:
        "Avenue, healthcare, remote-patient, monitoring, virtual care, telehealth services, patient, care, management",
      canonical: `${base}`,
    },
    payors: {
      description,
      title: `Payors | ${title}`,
      keywords:
        "Avenue, healthcare, remote-patient, monitoring, virtual care, telehealth services, patient, care, management, payors",
      canonical: `${base}/payors`,
    },
    products: {
      description,
      title: `Products | ${title}`,
      keywords:
        "Avenue, healthcare, remote-patient, monitoring, virtual care, telehealth services, patient, care, management, products",
      canonical: `${base}/product`,
    },
    providers: {
      description,
      title: `Providers | ${title}`,
      keywords:
        "Avenue, healthcare, remote-patient, monitoring, virtual care, telehealth services, patient, care, management, providers",
      canonical: `${base}/providers`,
    },
    contact: {
      description,
      title: `Contact | ${title}`,
      keywords:
        "Avenue, healthcare, remote-patient, monitoring, virtual care, telehealth services, patient, care, management, contact",
      canonical: `${base}/contact`,
    },
    about: {
      description,
      title: `About | ${title}`,
      keywords:
        "Avenue, healthcare, remote-patient, monitoring, virtual care, telehealth services, patient, care, management, about",
      canonical: `${base}/about`,
    },
    ourProgram: {
      description,
      title: `Our Programs | ${title}`,
      keywords:
        "Avenue offers Remote Patient Monitoring (RPM) and Chronic Care Management (CCM) services for patients that qualify.",
      canonical: `${base}/our-program`,
    },
    remotePatientMonitoring: {
      description,
      title: `Remote Patient Monitoring (RPM) | ${title}`,
      keywords:
        "Avenue offers Remote Patient Monitoring (RPM) and Chronic Care Management (CCM) services for patients that qualify.",
      canonical: `${base}/remote-patient-monitoring`,
    },
    chronicComplexCareManagement: {
      description,
      title: `Chronic/Complex Care Management (CCM) | ${title}`,
      keywords:
        "Avenue offers Remote Patient Monitoring (RPM) and Chronic Care Management (CCM) services for patients that qualify.",
      canonical: `${base}/chronic-care-management`,
    },
  };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="title" content={seoProps[page].title} />
      <title>{seoProps[page].title}</title>
      <meta name="Description" content={seoProps[page].description} />
      <meta name="Keywords" content={seoProps[page].keywords} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={seoProps[page].canonical} />
    </Helmet>
  );
};

export default SEO;
