import React, { Fragment, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Col, Container, Row } from "reactstrap";

import "./Product.scss";
import style from "./style.module.scss";
import SEO from "../../Components/SEO";
import "../../styles/style.scss";

import ItemCard from "../../Components/ItemCard";
import ProgramItemCard from "../../Components/ProgramItemCard";

const data = [
  {
    title: "Remote Patient Monitoring",
    icon: require("../../images/monitor.svg"),
  },
  {
    title: "Lifestyle Wellness",
    icon: require("../../images/wellness.svg"),
  },
  {
    title: "Chronic Care Management",
    icon: require("../../images/medical.svg"),
  },
  {
    title: "Post-Discharge/ Transition of Care",
    icon: require("../../images/hospital.svg"),
  },
  {
    title: "SDOH Health Member Identification",
    icon: require("../../images/member.svg"),
  },
];

const RemotePatientMonitoring = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <SEO page="remotePatientMonitoring" />
      <Header active="our-program" css={"spring-wood"} />
      <Container fluid className="bg-color-spring-wood">
        <div className="container pt-md-5 pb-lg-5">
          <Row>
            <Col md={12} className="pb-lg-3 pb-md-3">
              <h1>Remote Patient monitoring (RPM)</h1>
            </Col>
          </Row>
        </div>
      </Container>

      <div className={`${style.section} ${style.section_1}`}>
        <div className={style.section_inner}>
          <div className={style.data_box}>
            <div className={`${style.data_item} ${style.section_1_bg}`}>
              <ItemCard
                heading={"Who We Are?"}
                imageUrl={require("../../images/img8.png")}
                isTheme3
              >
                <p>
                  Avenue is a comprehensive, innovative care solutions company.
                  We see ourselves as a complementary service for the patients’
                  provider regardless of patient location. Avenue uses advanced
                  technologies that integrate with most Bluetooth and cellular
                  medical and consumer devices. This drives patient engagement
                  and ease of use resulting in reduced acute episodes of care.
                </p>
              </ItemCard>
            </div>
            <div className={`${style.data_item} ${style.section_2_bg}`}>
              <ItemCard
                heading={"Our Philosophy"}
                imageUrl={require("../../images/img9.png")}
                isTheme4
              >
                <div className={style.content_box1}>
                  <p>
                    At Avenue, we believe you can never take the human out of
                    healthcare. We believe that patient-provider relationships
                    drive positive patient outcomes and support lifelong healthy
                    behaviors.
                  </p>
                  <div className={style.data_content}>
                    <div data-heading={"h2"}>Who Are Our Partners?</div>
                    <p>
                      Any provider treating patients seeking to leverage remote
                      digital health services to improve health outcomes and
                      reduce acute episodes of care.
                    </p>
                  </div>
                </div>
              </ItemCard>
            </div>
          </div>
        </div>
      </div>
      {/* Our Program */}
      <div className={`${style.section} ${style.section_program}`}>
        <div className={style.section_inner}>
          <div data-container>
            <div data-heading={"h2"}>Our Holistic RPM Program</div>
          </div>
          <div className={style.program_main}>
            <div className={style.program_center}>
              <img src={require("../../images/center_icon.png")} alt="1" />
            </div>
            <div className={style.program_box}>
              {data.map((item, i) => {
                return (
                  <div className={style.program_item} key={i}>
                    <ProgramItemCard title={item.title} iconUrl={item.icon} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={`${style.section} ${style.section_2}`}>
        <div className={style.section_inner}>
          <div className={style.data_box}>
            <div className={`${style.data_item} ${style.section_3_bg}`}>
              <ItemCard
                heading={"How Can We Help?"}
                imageUrl={require("../../images/img10.png")}
                isTheme3
              >
                <p>
                  We provide additional touch points with patients between
                  office visits to assist provider to proactively intervene and
                  adjust treatment plans to reduce urgent care, ER visits or
                  hospitalizations.
                </p>
                <p>
                  We have expertise in all clinical environments and geographic
                  locations across the U.S. including rural areas.
                </p>
              </ItemCard>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.section} ${style.section_green}`}>
        <div className={style.section_inner}>
          <div data-container>
            <div className={style.help_content}>
              <div className={style.help_left}>
                <div data-heading={"h2"}>We Help You Manage</div>
              </div>
              <div className={style.help_right}>
                <ul data-list2>
                  <li>
                    <div data-heading={"h3"}>HTN, Diabetes, COPD, CHF</div>
                  </li>
                  <li>
                    <div data-heading={"h3"}>Oncology</div>
                  </li>
                  <li>
                    <div data-heading={"h3"}>BMI optimization</div>
                  </li>
                  <li>
                    <div data-heading={"h3"}>Hip/Knee Surgery</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.section} ${style.section_3}`}>
        <div className={style.section_inner}>
          <div className={style.data_box}>
            <div className={`${style.data_item} ${style.section_4_bg}`}>
              <ItemCard
                heading={"Reimbursement"}
                imageUrl={require("../../images/img11.png")}
                isTheme4
              >
                <div className={style.content_box2}>
                  <p>
                    Avenue’s program allows providers to seek reimbursement via
                    remote-physiological monitoring (RPM) and chronic care
                    management (CCM) procedure codes.
                  </p>
                </div>
              </ItemCard>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.section} ${style.section_4}`}>
        <div data-container>
          <div className={style.heading_box}>
            <div data-container>
              <div data-heading={"h2"}>What Customers Are Saying?</div>
            </div>
          </div>
          <div className={style.section_inner}>
            <div className={style.testi_box}>
              <div className={style.testi_left}>
                <div className={style.testi_img}>
                  <img src={require("../../images/testi_img.png")} alt="1" />
                </div>
              </div>
              <div className={style.testi_right}>
                <div className={style.testi_text}>
                  <div className={style.quote1}>
                    <img src={require("../../images/quote.svg")} alt="1" />
                  </div>
                  <div className={style.quote2}>
                    <img src={require("../../images/quote.svg")} alt="1" />
                  </div>
                  <div className={style.text_data}>
                    <p>
                      ”When we partnered with Avenue over a year ago, we were
                      hoping to improve the health of our patients while
                      empowering them with self-management tools. What we have
                      seen has far exceeded our expectations. Not only have
                      these goals been realized but we have a true partner in
                      care for our patients.”
                    </p>
                    <div className={style.author}>
                      - Sisselman Medical Group
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default RemotePatientMonitoring;
