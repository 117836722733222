import React, {Fragment, useEffect} from 'react';
import {Col, Container, Row} from 'reactstrap';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import ContactUs from '../../Components/ContactUs';
import doctor from '../../assets/img/homepage/doctor-homepage.png';
import doctorLarge from '../../assets/img/homepage/doctor-homepage-large.png';
import providerIcon from '../../assets/img/icons/asset-3.svg';
import buildingIcon from '../../assets/img/icons/asset-27.svg';
import virtualCare from '../../assets/img/icons/asset-16.svg'
import payorsIcon from '../../assets/img/icons/asset-28.svg';
import arrowLeft from '../../assets/img/homepage/arrow-left.svg';
import circleAvenueMobile from '../../assets/img/homepage/avenue-circle-mobile.png';
import plus from '../../assets/img/homepage/plus.png';
import equal from '../../assets/img/homepage/equal.png';
import avenueFigure from '../../assets/img/homepage/home-graphic.svg';
import './home.scss'
import {Link} from "react-router-dom";
import CheckmarkImg from "../../assets/img/circle-checkmark.svg";
import Section1 from "./partials/Section1";
import SEO from "../../Components/SEO/";

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Fragment>
      <SEO page="home" />
      <Header/>
      {/* Hero */}
      <Container fluid className="spring-wood-line-bg">
        <div className="container-fluid-avenu">
          <Row className={'home-hero'}>
            <Col md='7' className="hero-home-text oval-bg">
              <div>
                <h1>Welcome to Avenue</h1>
                <p className="subheading">We partner with you to launch fully-staffed remote-patient monitoring and virtual care programs.</p>
              </div>
            </Col>
            <Col md='5' className="hero-home-img p-right-0">
              <img src={doctor} alt="Wanderly" className="doctor-img float-right"/>
              <img src={doctorLarge} alt="Wanderly" className="doctor-img float-right large"/>
              <div className="clearfix d-block d-md-none"/>
            </Col>
          </Row>
        </div>
      </Container>

      {/* Hero Text */}
      <Container fluid className={'hero-text bg-color-spring-wood'}>
        <div className="container-fluid-avenu">
          <section className="row">
            <Col md={12}>
              <h2 className="text-center d-none d-md-block m-top-100 m-bottom-60">
                We partner with you to launch and scale your <br/> remote-patient monitoring program.
              </h2>
              <h3 className="text-center d-block d-md-none m-top-30 m-bottom-40">
                We partner with you <br/> to launch and scale your <br/> remote-patient monitoring program.
              </h3>
            </Col>
          </section>
        </div>
      </Container>

      {/* Mobile Hero */}
      <Container fluid className="bg-color-spring-wood d-block d-md-none">
        <div className="container-fluid-avenu">
          <section className={'row'}>
            <Col md={12}>
              <div className="text-center">
                <img src={providerIcon} alt="Providers" className="icon-home"/>
              </div>
            </Col>
            <Col md={12}>
              <div className="text-center">
                <h3>Avenue</h3>
              </div>
            </Col>
            <Col md={12}>
              <div className="text-center subheading" style={{fontSize: '20px'}}>
                Technology Platform
              </div>
            </Col>

            <Col md={12}>
              <div className="text-center p-top-40 p-bottom-40">
                <img src={plus} alt="Plus"/>
              </div>
            </Col>

            <Col md={12}>
              <div className="text-center">
                <img src={virtualCare} alt="Providers" className="icon-home"/>
              </div>
            </Col>
            <Col md={12}>
              <div className="text-center">
                <h3>Avenue</h3>
              </div>
            </Col>

            <Col md={12}>
              <div className="text-center subheading" style={{fontSize: '20px'}}>
                Virtual Care Team
              </div>
            </Col>

            <Col md={12}>
              <div className="text-center p-top-40">
                <img src={equal} alt="Equal"/>
              </div>
            </Col>

            <Col md={12}>
              <div className="text-center m-top-30">
                <img src={circleAvenueMobile} alt="" height="195"/>
              </div>
            </Col>

            <Col md={12} className="green-line-mobile">
              <Row>
                <Col md={12} className="text-center bg-circle-mobile first-child">
                  Managed Remote-Patient Monitoring
                </Col>
              </Row>
              <Row>
                <Col md={12} className="text-center bg-circle-mobile">
                  Digital Patient Experiences
                </Col>
              </Row>
              <Row>
                <Col md={12} className="text-center bg-circle-mobile last-child">
                  Digital Care Management
                </Col>
              </Row>
            </Col>

          </section>
        </div>
      </Container>

      {/* Mobile Hero */}
      <Container fluid className="bg-color-spring-wood d-none d-md-block">
        <div className="container-fluid-avenu">
          <div className="figure-avenue">
            <img src={avenueFigure} alt="" className="img-fluid" style={{width: '100%'}}/>
          </div>
        </div>
      </Container>

      <Container fluid className={'bg-color-spring-wood'}>
        <div className="container-fluid-avenu">
          <section className="row">
            <Col md={12} className="text-center">
              <h2 className="text-center d-none d-md-block m-top-100">Patients are expecting digital experiences.</h2>
              <h3 className="text-center d-block d-md-none m-top-30">Patients are expecting <br/> digital experiences.
              </h3>
              <p className="p-top-20 m-bottom-100">
                <Link className="large no-underline" to={`/about`}> Learn About Our Approach <img src={arrowLeft}
                                                                                                  alt="Learn About Our Approach"/>
                </Link>
              </p>
            </Col>
          </section>
        </div>
      </Container>

      {/* We Believe in Humans */}
      <div className={'section-1-mobile'}><Section1/></div>
      <div className={'section-1-desktop'}><Section1/></div>

      <Container fluid className={'outcomes-spring-wood'}>
        <div className="container-fluid-avenu">
          <section className={'row our-capabilities'}>
            <Col lg={3} className={'left-side offset-sm-1'}>
              <h2>Our Capabilities</h2>
            </Col>
            <Col lg={7} className={'right-side'}>
              <Row className="mb-4 no-margin">
                <Col lg={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'checkmark-circle'}/>
                </Col>
                <Col lg={11}>
                  <h3>Managed Remote-Patient Monitoring</h3>
                  <p>We provide managed RPM solutions that help reduce barriers to implementation for healthcare organizations. Our full-service virtual care team solution allows for modular program development.</p>
                </Col>
              </Row>
              <Row className="mb-4 no-margin">
                <Col lg={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'checkmark-circle'}/>
                </Col>
                <Col lg={11}>
                  <h3>Digital Care Management</h3>
                  <p>We use mobile technology to facilitate real-time data collection and communication between patient
                    and care team. We help our partners scale digital-enabled evidence-based interventions.</p>
                </Col>
              </Row>
              <Row className="mb-4 no-margin">
                <Col lg={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'checkmark-circle'}/>
                </Col>
                <Col lg={11}>
                  <h3>Digital Patient Engagement</h3>
                  <p>Patients are learning to expect high-quality digital experiences of care. Our products are patient-tested to improve engagement and satisfaction.</p>
                </Col>
              </Row>
              <Row className="mb-4 no-margin">
                <Col lg={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'checkmark-circle'}/>
                </Col>
                <Col lg={11}>
                  <h3>Disease and Episode of Care Management</h3>
                  <p>Our platform and clinical staff can be tailored to specific episode's of care and conditions. Our
                    clinical staff represent a wide range of specialties and experience from critical care to chronic
                    disease management.</p>
                </Col>
              </Row>
              <Row className="mb-4 no-margin">
                <Col lg={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'checkmark-circle'}/>
                </Col>
                <Col lg={11}>
                  <h3>Scalable Workforce Solutions</h3>
                  <p>Our technology allows for on-demand deployment of virtual care teams and programs that are powered by our credentialing and clinician-staffing engine. Our modular system allows us to support large and small patient populations.</p>
                </Col>
              </Row>
              <Row className="pb-md-5 pb-lg-5 no-margin">
                <Col lg={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'checkmark-circle'}/>
                </Col>
                <Col lg={11}>
                  <h3>Centralized Scheduling Technology</h3>
                  <p>Our proprietary scheduling technology allows for patient-clinician session scheduling and virtual care team coordination.</p>
                </Col>
              </Row>
            </Col>
          </section>
        </div>
      </Container>
      <Container fluid>
        <div className="container-fluid-avenu">
          <section className={'row'}>
            <Container>
              <Row  className="provider-payors-footer">
                <Col md='6' className="p-top-50">
                  <img src={buildingIcon} alt="Providers" className="icon-home"/>
                  <h3>Providers</h3>
                  <p className={'body-copy-large p-top-20 lh-1-5'}>
                    Our managed RPM and CCM services are easily integrated into your practice settings.
                  </p>
                  <div className="p-top-10">
                    <ul className="list-home-page">
                      <li>Generate New Clinic Revenue</li>
                      <li>Provide Quality Digital Experiences</li>
                      <li>Improve Patient Care</li>
                    </ul>
                  </div>
                  <p className="ptb-10">
                    <Link className="large no-underline" to={`/providers`}> Learn About Our Provider Partner Approach <img
                      src={arrowLeft} alt="Learn About Provider Partner Approach"/></Link>
                  </p>

                </Col>
                <Col md='6' className="p-top-50">
                  <img src={payorsIcon} alt="Payors and At-Risk Programs" className="icon-home"/>
                  <h3>Payors and At-Risk Programs</h3>
                  <p className={'body-copy-large p-top-20 lh-1-5'}>
                    We employ evidence-based care management that improves outcomes and quality metrics.
                  </p>
                  <div className="p-top-10">
                    <ul className="list-home-page">
                      <li>Reduce Costs</li>
                      <li>Enhance Your Care Management Capabilities</li>
                      <li>Drive Better Quality Metrics</li>
                    </ul>
                  </div>
                  <p className="ptb-10 ">
                    <Link className="large no-underline" to={`/payors`}> Learn About Our Payor Program <img src={arrowLeft}
                                                                                                        alt="Learn About Payor Program"/></Link>
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Container>
      <ContactUs/>
      <Footer/>
    </Fragment>
  )
};

export default Home;
