import React, {Fragment, useEffect} from 'react';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import './Providers.scss';
import {Col, Container, Row} from "reactstrap";
import revenueCenterImg from "../../assets/img/providers/providers-1.png";
import revenueCenterLgImg from "../../assets/img/providers/providers-1(xl).png";
import scalableImg from "../../assets/img/providers/providers-2.png";
import digitalExpImg from "../../assets/img/providers/providers-3.png";
import digitalExpLgImg from "../../assets/img/providers/providers-3(xl).png";
import DotsImg from "../../assets/img/providers/providers-graphic.svg";
import OrangeImg from "../../assets/img/providers/orange.svg";
import BlueImg from "../../assets/img/providers/blue.svg";
import LightBlueImg from "../../assets/img/providers/light-blue.svg";
import YellowImg from "../../assets/img/providers/yellow.svg";
import CheckmarkImg from "../../assets/img/circle-checkmark.svg";
import ContactUs from "../../Components/ContactUs";
import partnerProgramImg from "../../assets/img/providers/providers-graphic1.svg";
import partnerProgramMobileImg from "../../assets/img/providers/providers-graphic1(mobile).svg";
import SEO from "../../Components/SEO";

const Providers = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Fragment>
      <SEO page="providers" />
      <Header active="providers" css={'spring-wood'}/>

      <Container fluid className="bg-color-spring-wood">
        <div className="container pt-md-5 pb-lg-5">
          <Row>
            <Col md={12} className="pb-lg-3 pb-md-3">
              <h1>Providers</h1>
            </Col>
            <Col md={12}>
              <p className="subheading lh-1-5 full-width">
                Our scalable model allows for highly customizable care model <br/>
                implementation for your clinic.
              </p>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="partner-program">
        <div className="container-fluid-avenu">
          <section className={'text-center link-section'}>
            <Row>
              <Col md={12} className="pb-5 pt-5">
                <h2>Our Provider Partner Program</h2>
              </Col>
              <Col md={12} className="no-padding-col d-none d-md-block">
                <img src={partnerProgramImg} alt="Multiple Dots" className="partner-program-img"/>
              </Col>
              <Col md={12} className="d-block d-md-none">
                <img src={partnerProgramMobileImg} alt="Multiple Dots" className="partner-program-img"/>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Container fluid className="outcomes-aqua">
        <div className="container-fluid-avenu">
          <section className={'row'}>
            <Col sm={4} className={'left-side'}>
              <h2 className="ml-xl-5">Outcomes</h2>
            </Col>
            <Col sm={8} className={'right-side'}>
              <Row className={'mb-4'}>
                <Col sm={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'mt-1'}/>
                </Col>
                <Col sm={10}>
                  <h3>Generate New Clinic Revenue</h3>
                  <p>
                    Our model allows a clinic or practice to launch new service lines that generate revenue. We remove
                    workflow disruption and the need to hire new clinical staff.
                  </p>
                </Col>
              </Row>
              <Row className={'mb-4'}>
                <Col sm={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'mt-1'}/>
                </Col>
                <Col sm={10}>
                  <h3>High Quality Digital Experiences</h3>
                  <p>
                    In a digital world, patients are increasingly expecting a digital experience from their healthcare providers. Our digital tools provide data collection, engaging educational materials, and proactive health experiences for patients.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'mt-1'}/>
                </Col>
                <Col sm={10}>
                  <h3>Improved Patient Care</h3>
                  <p>
                    Our care management models are build in collaboration with our partners and are based on strong
                    clinical evidence to improve patient outcomes and experience of care.
                  </p>
                </Col>
              </Row>
              <Row className="pb-5">
                <Col sm={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'mt-1'}/>
                </Col>
                <Col sm={10}>
                  <h3>Reduced Emergency Department Visits and Hospitalizations</h3>
                  <p>
                    We employ evidence-based interventions to reduce ED and acute-care utilization.
                  </p>
                </Col>
              </Row>
            </Col>
          </section>
        </div>
      </Container>

      <section><h2 className={'text-center page-title'}>Our Partnership Program</h2></section>

      <Container fluid className="revenue-center">
        <div className="container-fluid-avenu">
          <section className={'d-flex'}>
            <Row>
              <Col md='6' className={'text-center pl-0'}>
                <img src={revenueCenterImg} className={'section-image float-left'} alt={'Facility'}/>
                <img src={revenueCenterLgImg} className={'section-image float-left large'} alt={'Facility'}/>
              </Col>
              <Col md='6' lg={'5'} className={'align-self-center section-text'}>
                <h3>A Revenue Center</h3>
                <p className="body-copy-large lh-1-5">
                  Our provider partnerships help grow your service offerings to your patients, while at the same
                  time
                  generating growth.
                </p>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Container fluid className="ondemand-care">
        <div className="container-fluid-avenu">
          <section className={'d-flex'}>
            <Row className={'mobile-reverse'}>
              <Col md='6' lg={'5'} className={'align-self-center offset-lg-1 text'}>
                <h3>
                  Scalable, On-Demand Care <br/>
                  Management Models
                </h3>
                <p className="body-copy-large lh-1-5">
                  Our technology + staffing model allows for rapid deployment and quick scalability of care
                  management programs and care teams to support your patients as needs change.
                </p>
              </Col>
              <Col md='6'>
                <img src={scalableImg} className={'scalable-on-demand-img section-image float-right'} alt={'Clinician'}/>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Container fluid className="digital-experience-providers">
        <div className="container-fluid-avenu">
          <section className={'d-flex flex-row'}>
            <Row className={'mobile-reverse'}>
              <Col md='6' className={'align-self-center pl-0'}>
                <img src={digitalExpImg} className={'section-image float-left'} alt={'Ipad'}/>
                <img src={digitalExpLgImg} className={'section-image float-left large'} alt={'Ipad'}/>
              </Col>
              <Col md='6' className={'align-self-center text'}>
                <h3>An Engaging Digital Experience</h3>
                <p className="body-copy-large lh-1-5">
                  Your patients are expecting digital experiences as their lives become encompassed by new products and
                  services. Our model allows you to improve patient satisfaction through mobile patient engagement.
                </p>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Container fluid className="break-down-barriers">
        <div className="container-fluid-avenu">
          <section className={'row'}>
              <Col md={12} className="text-center">
                  <h2>We help break down common barriers for providers.</h2>
              </Col>
            <div><img src={DotsImg} alt="Multiple Dots" className="single-image dots-image"/></div>
            <div className="many-images">
              <div className={'row'}>
                <Col md={12} className="text-center">
                    <img src={OrangeImg} alt="Orange Circle"/>
                </Col>
                <Col md={12} className="text-center">
                  <h3>Upfront Capital Requirements</h3>
                </Col>
              </div>
              <div className={'row'}>
                  <Col md={12} className="text-center">
                      <img src={YellowImg} alt=" Yellow Circle"/>
                  </Col>
                  <Col md={12} className="text-center">
                      <h3>Access to Clinical Workforce</h3>
                  </Col>
              </div>
              <div className={'row'}>
                  <Col md={12} className="text-center">
                      <img src={BlueImg} alt="Blue Circle"/>
                  </Col>
                  <Col md={12} className="text-center">
                      <h3>Workflow Disruption</h3>
                  </Col>
              </div>
              <div className={'row'}>
                  <Col md={12} className="text-center">
                      <img src={LightBlueImg} alt="Light Blue Circle"/>
                  </Col>
                  <Col md={12} className="text-center">
                      <h3>Navigating Technologies</h3>
                  </Col>
              </div>
            </div>
          </section>
        </div>
      </Container>
      <ContactUs/>
      <Footer/>
    </Fragment>
  )
};

export default Providers;
