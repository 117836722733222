import React, {Fragment} from 'react';

const MobileJourney = () => {
  return (
    <Fragment>
      <div className={'journey-item'}>
        <span className="circle yellow-orange"/>
        <h3>Download</h3>
        <div className={'item-text'}>Patients download our app in the clinic and learn about the program.</div>
      </div>

      <div className={'journey-item'}>
        <span className="circle swans-down"/>
        <h3>Engage</h3>
        <div className={'item-text'}>Our team schedules the patient for the first virtual visit.</div>
      </div>

      <div className={'journey-item'}>
        <span className="circle neptune"/>
        <h3>Educate</h3>
        <div className={'item-text'}>Our clinicians educate patients on care plan adherence, lifestyle interventions, and their clinical metrics.</div>
      </div>

      <div className={'journey-item'}>
        <span className="circle marzipan"/>
        <h3>Build</h3>
        <div className={'item-text'}>We build relationships and trust between patients and our care teams.</div>
      </div>

      <div className={'journey-item'}>
        <span className="circle yellow-orange"/>
        <h3>Monitor</h3>
        <div className={'item-text'}>We help patients monitor their own numbers and step in if needed.</div>
      </div>

      <div className={'journey-item'}>
        <span className="circle swans-down"/>
        <h3>Elevate</h3>
        <div className={'item-text'}>Our program elevates patient self-efficacy, satisfaction, and health status.</div>
      </div>
    </Fragment>
  )
};

export default MobileJourney;
