import React, {Fragment, useState} from 'react';
import {Row, Col, Container} from 'reactstrap';
import {history} from "../../Utils/history";
import './Contact.scss';

const ContactUs = () => {
    const [typeContact, setTypeContact] = useState('provider');

    const onChange = (e) => {
        let {value} = e.target;
        setTypeContact(value);
    };

    const onClick = () => {
      history.push(`/contact?type=${typeContact}`);
    };

    return (
        <Fragment>
            <Container fluid className="contact-bg">
                <section className="contact-us row">
                    <Container>
                        <Row>
                            <Col md='12' className="p-top-30 p-bottom-10 d-block d-md-none">
                                <h3 >Let’s work together.</h3>
                            </Col>
                            <Col md='12' className="p-top-10 d-block d-md-none">
                                <Row>
                                    <Col md="8">
                                        Send us your contact information, and we’ll reach out!
                                    </Col>
                                    <Col md="4" className="p-top-30 p-bottom-30">
                                        <button className="large btn-primary" onClick={onClick}>Contact Us</button>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg='5' className="d-none d-md-block">
                                <h2>Let’s work together.</h2>
                            </Col>
                            <Col lg='7' className="d-none d-md-block">
                                <Row>
                                    <Col md="8">
                                        <select onChange={onChange} className="form-control">
                                            <option value="provider"> I'm a provider and I'd like to know more </option>
                                            <option value="payor"> I'm a payor and I'd like to know more </option>
                                            <option value="risk"> I'm a At Risk Program and I'd like to know more </option>
                                            <option value="other"> Other </option>
                                        </select>
                                    </Col>
                                    <Col md="4">
                                        <button className="large btn-primary btn-wide" onClick={onClick}>Contact Us</button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>
        </Fragment>
    )
};

export default ContactUs;
