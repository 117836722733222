import React from "react";

import style from "./style.module.scss";

function ExperienceCard({
  heading,
  children,
  iconUrl,
  count,
  isAlignRight,
  isTheme1,
  isTheme2,
  isTheme3,
}) {
  return (
    <div
      className={`${style.exp_card} ${isAlignRight && style.align_right} ${
        isTheme1 && style.theme1
      } ${isTheme2 && style.theme2} ${isTheme3 && style.theme3}`}
    >
      <div className={style.left}>
        <div className={style.content_box}>
          <div data-heading={"h3"} data-title={`Step ${count}. `}>
            {heading}
          </div>
          <div data-content>{children}</div>
        </div>
      </div>
      <div className={style.right}>
        <div className={style.count_box}>
          <div className={style.icon}>
            <img src={iconUrl} alt={count} />
          </div>
          <div className={style.count}>0{count}</div>
        </div>
      </div>
    </div>
  );
}

export default ExperienceCard;
