import React from "react";

import style from "./style.module.scss";
import { Link } from "react-router-dom";

function ProgramCard({
  imageUrl,
  heading,
  children,
  buttonText,
  link,
  isAlignRight,
}) {
  return (
    <div className={`${style.card} ${isAlignRight && style.right}`}>
      <div className={style.img_box}>
        <div className={style.img}>
          <img src={imageUrl} alt={heading} />
        </div>
      </div>
      <div className={style.info}>
        <div className={style.heading_box}>
          <div data-heading={"h3"}>{heading}</div>
        </div>
        <div data-content>{children}</div>
        <div className={style.link}>
          <Link to={link} data-title="Learn More" className={style.link_text}>
            {buttonText} <span className={style.arrow_right}></span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProgramCard;
