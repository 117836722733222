import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import logoDesktop from "../../assets/img/brand/logo-desktop.svg";
import "./Header.scss";

const Header = ({ active, css }) => {
  const [displayMobile, setDisplayMobile] = useState(false);

  const toggle = () => {
    setDisplayMobile(!displayMobile);
  };

  return (
    <Row className={`header-nav ${css || ""}`}>
      <Col md="4" className="d-none d-md-flex">
        <div className="logo-container">
          <Link to={`/`}>
            <img src={logoDesktop} alt="avenu" className="logo" />
          </Link>
        </div>
      </Col>
      <Col md="8" className="d-none d-md-flex menu-container">
        <div className="menu align-self-center">
          <div className={`item ${active === "about" ? "active" : ""}`}>
            <Link to={`/about`}>About Us </Link>
          </div>
          <div className={`item ${active === "product" ? "active" : ""}`}>
            <Link to={`/product`}>Our Product</Link>
          </div>
          <div className={`item ${active === "our-program" ? "active" : ""}`}>
            <Link to={`/our-program`}>Programs</Link>
          </div>
          <div className={`item ${active === "providers" ? "active" : ""}`}>
            <Link to={`/providers`}>Providers</Link>
          </div>
          <div className={`item ${active === "payors" ? "active" : ""}`}>
            <Link to={`/payors`}>Payors</Link>
          </div>
          <div className={`item ${active === "contact" ? "active" : ""}`}>
            <Link to={`/contact`}>Contact</Link>
          </div>
        </div>
      </Col>
      <Col md="6" className="d-block d-md-none">
        <div className="logo-container-mobile">
          <Link to={`/`}>
            <img src={logoDesktop} alt="avenu" className="logo-mobile" />
          </Link>
        </div>
      </Col>
      <Col md="6" className="d-block d-md-none">
        <div className="menu">
          <div className="item mobile-menu-label" onClick={toggle}>
            <span className="navbar-toggler-icon"></span>
          </div>
        </div>
      </Col>
      <Col
        md={12}
        className={`d-block d-md-none menu-mobile ${
          !displayMobile ? "hide" : "show"
        }`}
      >
        <div className={`item ${active === "about" ? "active" : ""}`}>
          <Link className="p-2" to={`/about`}>
            About Us
          </Link>
        </div>
        <div className={`item ${active === "product" ? "active" : ""}`}>
          <Link className="p-2" to={`/product`}>
            {" "}
            Our Product
          </Link>
        </div>
        <div className={`item ${active === "our-program" ? "active" : ""}`}>
          <Link className="p-2" to={`/our-program`}>
            {" "}
            Programs
          </Link>
        </div>

        <div className={`item ${active === "providers" ? "active" : ""}`}>
          <Link className="p-2" to={`/providers`}>
            Providers
          </Link>
        </div>
        <div className={`item ${active === "payors" ? "active" : ""}`}>
          <Link className="p-2" to={`/payors`}>
            Payors
          </Link>
        </div>
        <div className={`item ${active === "contact" ? "active" : ""}`}>
          <Link className="p-2" to={`/contact`}>
            Contact
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
