import React, {Fragment} from 'react';
import {Col, Container, Row} from 'reactstrap';
import weBelieveImg from "../../../assets/img/homepage/home-2.png";

const Section1 = () => {
  return (
    <Fragment>
      <Container fluid className="yellow-line-bg">
        <div className="container-fluid-avenu">
          <Row>
            <Col md='6' className="no-padding-col text-center pt-xl-5 d-none d-md-block">
              <img src={weBelieveImg} alt="Wanderly" className="laptop-home "/>
            </Col>
            <Col md='6' className="d-block d-md-none text-center">
              <img src={weBelieveImg} alt="Wanderly" className="laptop-home mt-5"/>
            </Col>
            <Col md='6' className="no-padding-col text">
              <Col md='12' className="pt-xl-5 mt-sm-5 plr-5">
                <h2>We believe humans are essential to healthcare technology innovation.</h2>
              </Col>
              <Col md='12' className="plr-5 text">
                <div className="body-copy-large p-top-30 p-bottom-30 lh-1-5">
                  Too often, technology companies enter healthcare
                  with automation in mind. At Avenue, we believe in using technology to empower the human clinician
                  with actionable data and effective communication tools.
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
};

export default Section1;
