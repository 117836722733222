import React, {Fragment, useEffect} from 'react';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import {Link} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";
import behavioralDimension from "../../assets/img/product/product-1.png";
import Product3 from "../../assets/img/product/product-3.png";
import arrowLeft from "../../assets/img/homepage/arrow-left.svg";
import ReducedImg from "../../assets/img/reduced.svg";
import ImprovedImg from "../../assets/img/improved.svg";
import ContactUs from "../../Components/ContactUs";
import JourneyImg from "../../assets/img/product/product-graphc.svg";
import cognitiveDimensionImg from "../../assets/img/product/product-2.png";
import cognitiveDimensionLgImg from "../../assets/img/product/product-2(xl).png";
import './Product.scss';
import MobileJourney from "./partials/MobileJourney";
import SEO from "../../Components/SEO";

const Product = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Fragment>
      <SEO page="products" />
      <Header active="product" css={'spring-wood'}/>

      <Container fluid className="bg-color-spring-wood">
        <div className="container pt-md-5 pb-lg-5">
          <Row>
            <Col md={12} className="pb-lg-3 pb-md-3">
              <h1>Our Product</h1>
            </Col>
            <Col md={12}>
              <p className="subheading lh-1-5 full-width">
                We believe only human clinicians can provide excellent
                support to patients.
              </p>
            </Col>
          </Row>
        </div>
      </Container>


      <section>
        <Row>
          <Col md={12}>
            <h2 className="text-center pt-5 pb-3">How It Works</h2>
          </Col>
        </Row>
      </section>

      <Container fluid className="behavioral-dimension">
        <div className="container-fluid-avenu">
          <section className={'d-flex justify-content-around'}>
            <Row>
              <Col md='6' className={'text-center'}>
                <img src={behavioralDimension} className="section-image behavioral-dimension-img" alt=""/>
              </Col>
              <Col md='6' className={'align-self-center section-text'}>
                <h3>A Behavioral Dimension</h3>
                <h4 className={'mb-4'}>behavioral interviews and strategies</h4>
                <p>
                  Our model is based around a relationship that is crucial to identifying barriers to treatment plan
                  compliance and helping patients stay on track.
                </p>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Container fluid className="cognitive-dimension">
        <div className="container-fluid-avenu">
          <section className={'d-flex justify-content-end mobile-reverse'}>
            <Row className="mobile-reverse">
              <Col md='5' className={'align-self-center offset-md-1'}>
                <div className={'section-text'}>
                  <h3>A Cognitive Dimension</h3>
                  <h4 className={'mb-4'}>Clinical Data</h4>
                  <p>
                    We empower patients and clinicians with real-time and actionable clinical data to support
                    longitudinal
                    disease management.
                  </p>
                </div>
              </Col>
              <Col md='6' className={'text-center pr-0'}>
                <img src={cognitiveDimensionImg} className={'section-image float-right'} alt=""/>
                <img src={cognitiveDimensionLgImg} className={'section-image float-right large'} alt=""/>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Container fluid className="emotional-dimension">
        <div className="container-fluid-avenu">
          <section className={'d-flex justify-content-around'}>
            <Row>
              <Col md='6' className={'text-center'}>
                <img src={Product3} className={'section-image emotional-dimension-img'} alt=""/>
              </Col>
              <Col md='6' lg={'5'} className={'align-self-center section-text'}>
                <h3>An Emotional Dimension</h3>
                <h4 className={'mb-4'}>Human Support and Coaching</h4>
                <p>
                  You can never take the human out of healthcare. Relationships between clinicans and patients help
                  drive patient outcomes and support lifelong health behaviors.
                </p>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <section className={'text-center link-section'}>
        <Link className="large no-underline" to={`/providers`}>
          Find Out More <img src={arrowLeft} alt="Learn About Payor Program"/>
        </Link>
      </section>

      <Container fluid className="journey-container">
        <div className="container-fluid-avenu">
          <section className={'text-center link-section'}>
            <Row>
              <Col md={12} className="pb-4">
                <h2>We support patients along their care journey.</h2>
              </Col>
              <Col md={12} className="no-padding-col">
                <div style={{margin: '0 -30px'}} className={'journey-desktop'}>
                  <img src={JourneyImg} alt="Multiple Dots" className="journey-img"/>
                </div>
                <div className="journey-mobile">
                  <MobileJourney/>
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Container fluid className="outcomes-evidence">
        <div className="container-fluid-avenu">
          <section className="row">
            <Col lg={4} className={'left-side'}>
              <h3 className="ml-xl-5">Outcomes and Evidence</h3>
            </Col>
            <Col lg={8} className={'right-side'}>
              <Row className="mb-4 offset-xl-1">
                <Col lg={6} className="pl-xl-0">
                  <div className={'reduced-text'}><img src={ReducedImg} alt=""/> <span className={'vcenter'}>Reduced</span>
                  </div>
                  <h3>Emergency Department Visits*</h3>
                </Col>
                <Col lg={6}>
                  <div className={'reduced-text'}><img src={ReducedImg} alt=""/> <span className={'vcenter'}>Reduced</span>
                  </div>
                  <h3>Hospitalizations**</h3>
                </Col>
              </Row>
              <Row className="mb-4 offset-xl-1">
                <Col lg={6} className="pl-xl-0">
                  <div className={'improved-text'}><img src={ImprovedImg} alt=""/> <span className={'vcenter'}>enhanced</span>
                  </div>
                  <h3>Patient Experience***</h3>
                </Col>
                <Col lg={6}>
                  <div className={'improved-text'}><img src={ImprovedImg} alt=""/> <span className={'vcenter'}>improved</span>
                  </div>
                  <h3>Quality Metrics***</h3>
                </Col>
              </Row>

              <Row className={'m-top-50 offset-xl-1'}>
                <Col lg={12} className="pl-xl-0">
                  <h3 className={'mb-4'}>Patients are learning to expect a digital experience.</h3>
                  <p>
                    As the healthcare industry moves to digital, patients will seek services with organizations
                    that
                    provide engaging and effective digital care programs.
                  </p>

                  <ul className="sources list-unstyled source-list pr-md-5 pr-lg-5">
                    <li>*Totten AM, Womack DM, Eden KB, McDonagh MS, Griffin JC, Grusing S, Hersh WR. Telehealth:
                      Mapping
                      the Evidence for Patient Outcomes From Systematic Reviews. Technical Brief No. 26. Rockville, MD:
                      Agency for Healthcare Research and Quality; June 2016.
                    </li>
                    <li>**Noah, Benjamin, et al. “Impact of Remote Patient Monitoring on Clinical Outcomes: an Updated
                      Meta-Analysis of Randomized Controlled Trials.” Npj Digital Medicine, vol. 1, no. 1, 2018,
                      doi:10.1038/s41746-017-0002-4.
                    </li>
                    <li>***Kruse, Clemens Scott, et al. “Telehealth and Patient Satisfaction: a Systematic Review and
                      Narrative Analysis.” BMJ Open, vol. 7, no. 8, 2017, doi:10.1136/bmjopen-2017-016242.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </section>
        </div>
      </Container>

      <ContactUs/>
      <Footer/>
    </Fragment>
  )
};

export default Product;
