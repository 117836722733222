import React, { Fragment, useEffect } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Col, Container, Row } from "reactstrap";
import "./Product.scss";
import style from "./style.module.scss";
import SEO from "../../Components/SEO";
import ExperienceCard from "../../Components/ExperienceCard";
import ProgramCard from "../../Components/ProgramCard";
import "../../styles/style.scss";

const OurPrograms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <SEO page="ourProgram" />
      <Header active="our-program" css={"spring-wood"} />

      <Container fluid className="bg-color-spring-wood">
        <div className="container pt-md-5 pb-lg-5">
          <Row>
            <Col md={12} className="pb-lg-3 pb-md-3">
              <h1>Our Programs</h1>
            </Col>
            <Col md={12}>
              <p className="subheading lh-1-5 full-width">
                Avenue offers Remote Patient Monitoring (RPM) and Chronic Care
                Management (CCM) services for patients that qualify.
              </p>
            </Col>
          </Row>
        </div>
      </Container>

      <div className={`${style.section} ${style.section_1}`}>
        <div className={style.heading_box}>
          <div data-container>
            <div data-heading={"h2"}>The Avenue Patient Experience</div>
          </div>
        </div>
        <div className={style.exp_full}>
          <div data-container>
            <div className={style.exp_box}>
              <div className={style.exp_item}>
                <div className={style.line}></div>
                <ExperienceCard
                  heading={"Activities/Interventions"}
                  iconUrl={require("../../images/icon1.svg")}
                  count={"1"}
                  isTheme1
                >
                  <ul data-list>
                    <li>
                      Whole-Person Centered Comprehensive Clinical Assessment
                      and Care Planning
                    </li>
                    <li>Motivational Interviewing</li>
                    <li>Health/Dietary Coaching</li>
                    <li>Health Education</li>
                    <li>Remote-Patient Monitoring</li>
                    <li>Provider Collaboration</li>
                    <li>Care Coordination/Referrals</li>
                  </ul>
                </ExperienceCard>
              </div>
              <div className={style.exp_item}>
                <div className={style.line}></div>
                <ExperienceCard
                  heading={"Initial Outcomes Targets*"}
                  iconUrl={require("../../images/icon2.svg")}
                  count={"2"}
                  isAlignRight
                  isTheme2
                >
                  <ul data-list>
                    <li>Increased knowledge about medical condition(s)</li>
                    <li>Medication Education</li>
                    <li>Regular Activity/Exercise</li>
                    <li>Dietary changes</li>
                    <li>Improved Self-efficacy</li>
                    <li>Knowledge of Community Resources</li>
                  </ul>
                </ExperienceCard>
              </div>
              <div className={style.exp_item}>
                <div className={style.line}></div>
                <ExperienceCard
                  heading={"Health Outcomes Targets**"}
                  iconUrl={require("../../images/icon3.svg")}
                  count={"3"}
                  isTheme3
                >
                  <ul data-list>
                    <li>Improved blood pressure</li>
                    <li>Glycemic Management</li>
                    <li>BMI reduction</li>
                    <li>Medication Adherence</li>
                    <li>
                      Reduction in acute episodes of care / UC / ER /
                      Hospitalizations
                    </li>
                  </ul>
                </ExperienceCard>
              </div>
              <div className={style.exp_item}>
                <div className={style.line}></div>
                <ExperienceCard
                  heading={"Life Outcomes Targets***"}
                  iconUrl={require("../../images/icon4.svg")}
                  count={"4"}
                  isAlignRight
                  isTheme1
                >
                  <ul data-list>
                    <li>
                      Empowered to make future decisions about health and
                      well-being
                    </li>
                    <li>Maintenance of Health Behavior Changes</li>
                    <li>Reduced burden of healthcare costs</li>
                    <li>Increased life expectancy</li>
                    <li>Improved Quality of Life</li>
                  </ul>
                </ExperienceCard>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* program */}
      <div className={`${style.section} ${style.section_program}`}>
        <div className={style.heading_box}>
          <div data-container>
            <div data-heading={"h2"}>
              Learn More About Avenue’s Clinical Programs
            </div>
          </div>
        </div>
        <div className={style.program_full}>
          <div className={style.line_design1}></div>
          <div className={style.line_design2}></div>
          <div data-container>
            <div className={style.program_box}>
              <div className={style.program_item}>
                <ProgramCard
                  imageUrl={require("../../images/img1.png")}
                  heading={"Remote Patient Monitoring (RPM)"}
                  buttonText={"Learn About Remote Patient Monitoring"}
                  link={"/remote-patient-monitoring"}
                >
                  We provide a way to communicate important information, in
                  real-time about changes in your blood pressure allowing for
                  early intervention to your medical treatment plan to stay in
                  good health and avoid Acute episodes of Care, ER visits or
                  hospitalizations.
                </ProgramCard>
              </div>
              <div className={style.program_item}>
                <ProgramCard
                  imageUrl={require("../../images/img2.png")}
                  heading={"Avenue’s Chronic/Complex Care Management (CCM)"}
                  buttonText={"Learn About Chronic/Complex Care Management"}
                  link={"/chronic-care-management"}
                  isAlignRight
                >
                  We provide education, health coaching, and sharing of
                  resources to support the treatment plan of your primary care
                  provider. The program is designed for patients managing two or
                  more chronic health conditions.
                </ProgramCard>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className={`${style.section} ${style.section_content}`}>
        <div data-container>
          <div className={style.about_text}>
            <p>
              *Walker RC, Tong A, Howard K, Palmer SC. Patient expectations and
              experiences of remote monitoring for chronic diseases: Systematic
              review and thematic synthesis of qualitative studies. Int J Med
              Inform. 2019;124:78-85. doi:10.1016/j.ijmedinf.2019.01.013
            </p>
            <p>
              **Totten AM, Womack DM, Eden KB, et al. Telehealth: Mapping the
              Evidence for Patient Outcomes From Systematic Reviews. Rockville
              (MD): Agency for Healthcare Research and Quality (US); June 2016.
            </p>
            <p>
              **Leo DG, Buckley BJR, Chowdhury M, et al. Interactive Remote
              Patient Monitoring Devices for Managing Chronic Health Conditions:
              Systematic Review and Meta-analysis. J Med Internet Res.
              2022;24(11):e35508. Published 2022 Nov 3. doi:10.2196/35508
            </p>
            <p>
              ***Allegrante JP, Wells MT, Peterson JC. Interventions to Support
              Behavioral Self-Management of Chronic Diseases. Annu Rev Public
              Health. 2019;40:127-146.
              doi:10.1146/annurev-publhealth-040218-044008
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default OurPrograms;
