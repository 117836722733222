import React, {Fragment, useEffect} from 'react';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import {Container, Col, Row} from "reactstrap";
import CollaborativelyImg from '../../assets/img/payors/payors-1.png';
import PayorsImg from '../../assets/img/payors/payors-2.png';
import PayorsImgLarge from '../../assets/img/payors/payors-2(xl).png';
import HandphoneImg from '../../assets/img/payors/payors-3.png';
import SupportRapid from '../../assets/img/payors/payors-4.png'
import SupportRapidLarge from '../../assets/img/payors/payors-4(xl).png'
import ContactUs from "../../Components/ContactUs";
import payorsGraphicImg from "../../assets/img/payors/payors-graphic1.svg";
import payorsGraphicMobileImg from "../../assets/img/payors/payors-graphic(mobile).svg";
import CheckmarkImg from "../../assets/img/circle-checkmark.svg";
import './Payors.scss';
import SEO from "../../Components/SEO";

const Payors = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Fragment>
      <SEO page="payors" />
      <Header active="payors" css={'spring-wood'}/>

      <Container fluid className="bg-color-spring-wood">
        <div className="container pt-md-5 pb-lg-5">
          <Row>
            <Col md={12} className="pb-lg-3 pb-md-3">
              <h1>Payors</h1>
            </Col>
            <Col md={12}>
              <p className="subheading lh-1-5 full-width">
                We deploy highly scalable and evidence-based care <br/>
                management capabilities.
              </p>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="payors-graphic-container">
        <div className="container-fluid-avenu">
          <section className={'text-center link-section'}>
            <Row>
              <Col md={12} className="pb-5 pt-5">
                <h2>
                  We are a bolt-on care management partner to grow and enhance your existing capabilities.
                </h2>
              </Col>
              <Col md={12} className="no-padding-col d-none d-md-block">
                <img src={payorsGraphicImg} alt="Multiple Dots" className="payors-graphic-img"/>
              </Col>
              <Col md={12} className="d-block d-md-none">
                <img src={payorsGraphicMobileImg} alt="Multiple Dots" className="payors-graphic-img"/>
              </Col>
            </Row>
          </section>
        </div>
      </Container>

      <Container fluid className={'outcomes-pearl'}>
        <div className="container-fluid-avenu">
          <section className={'row'}>
            <Col md={4} className={'left-side text-center'}>
              <h2>Outcomes</h2>
            </Col>
            <Col md={8} className={'right-side'}>
              <Row className={'mb-4'}>
                <Col md={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'mt-1'}/>
                </Col>
                <Col md={11}>
                  <h3>Reduce Costs</h3>
                  <p>
                    Our evidence-based models allow you to scale a care management <br/>
                    program for your highest risk and highest cost populations. Our programs
                    enhance patient engagement, collect real-time clinical data, and allow for
                    targetted interventions that reduce emergency department and inpatient utilization.
                  </p>
                </Col>
              </Row>
              <Row className={'mb-4'}>
                <Col md={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'mt-1'}/>
                </Col>
                <Col md={11}>
                  <h3>Enhance and Extend Care Management Capabilities</h3>
                  <p>
                    Cast a wider net by providing services to more patients or target a high-risk
                    sub-group of interest. Our product allows for new care management program
                    deployment and growth of existing programs.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={1} className={'text-center'}>
                  <img src={CheckmarkImg} alt="Circle Checkmark" className={'mt-1'}/>
                </Col>
                <Col md={11}>
                  <h3>Drive Better Quality Metrics</h3>
                  <p>
                    More touchpoints with patients allows for better communication. Our mobile app allows for both passive and active communication with individuals and groups of members. Close more care gaps and improve clinical communication with your members.
                  </p>
                </Col>
              </Row>
            </Col>
          </section>
        </div>
      </Container>

      <Container fluid className="payors-graphic-container">
        <div className="container-fluid-avenu">
          <section className={'text-center link-section'}>
            <Row>
              <Col md={12} className="pb-0 pt-5">
                <h2>Our Partnership Program</h2>
              </Col>
            </Row>
          </section>
        </div>
      </Container>


      <Container fluid className="collaboratively-container">
        <div className="container-fluid-avenu">
          <Row>
            <Col md='6' className={'text-center'}>
              <img src={CollaborativelyImg} className={'section-image'} alt=""/>
            </Col>
            <Col md='6' className={'align-self-center text pr-3'}>
              <h3>
                Collaboratively build new or bigger care management capabilities.
              </h3>
              <p className="body-copy-large lh-1-5">
                Our clinical model and patient engagement technology allow for modular programs that fit
                specific patient populations. Avenue provides a turn-key digital care management solution.
              </p>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="evidence-based">
        <div className="container-fluid-avenu ">
          <Row className={'mobile-reverse'}>
            <Col md='5' className={'align-self-center offset-md-1 text'}>
              <h3>
                Scale evidence-based programs at lower cost and higher efficiency.
              </h3>
              <p className="body-copy-large lh-1-5">
                Our technology and behavioral science-based clinical models can be implemented and delivered cheaper and
                more effectively than many telephone-based models.
              </p>
            </Col>
            <Col md='6' className={'pr-0 text-right'}>
              <img src={PayorsImg} className={'section-image float-right'} alt=""/>
              <img src={PayorsImgLarge} className={'section-image float-right large'} alt=""/>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="digital-experience">
        <div className="container-fluid-avenu">
          <Row>
            <Col md='6' xl={5} className={'text-center'}>
              <img src={HandphoneImg} className={'section-image'} alt=""/>
            </Col>
            <Col md='6' xl={'5'} className={'align-self-center offset-xl-1 text'}>
              <h3>
                Provide members with a digital experience.
              </h3>
              <p className="body-copy-large lh-1-5">
                Your members are expecting a modern, digital experience from their health plan. Creating a better
                experience
                for members is a competitive advantage in a competitive market.
              </p>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="team-deployment">
        <div className="container-fluid-avenu">
          <Row className={'mobile-reverse'}>
            <Col md='5' className={'align-self-center offset-md-1 text'}>
              <h3>
                We support rapid and on-demand virtual care team deployment.
              </h3>
              <p className="body-copy-large lh-1-5">
                Our modular technology + staffing model provides a full-solution for managed care and health plans
                seeking
                the customized deployment of evidence-based digital care models.
              </p>
            </Col>
            <Col md='6' className={'pr-0 text-right'}>
              <img src={SupportRapid} className={'section-image float-right'} alt=""/>
              <img src={SupportRapidLarge} className={'section-image float-right large'} alt=""/>
            </Col>
          </Row>
        </div>
      </Container>

      <ContactUs/>
      <Footer/>
    </Fragment>
  )
};

export default Payors;
