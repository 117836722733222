import React from "react";

import style from "./style.module.scss";
import { Link } from "react-router-dom";

function ProgramItemCard({ iconUrl, title }) {
  return (
    <div className={style.item}>
      <div className={style.icon}>
        <img src={iconUrl} alt={title} />
      </div>
      <div className={style.info}>
        <div data-heading={"h3"}>{title}</div>
      </div>
    </div>
  );
}

export default ProgramItemCard;
