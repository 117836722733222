import React from "react";
import { Route, Switch, Router } from "react-router-dom";
import { history } from "./Utils/history";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Product from "./Pages/Product";
import Providers from "./Pages/Providers";
import Payors from "./Pages/Payors";
import Contact from "./Pages/Contact";
import OurPrograms from "./Pages/OurPrograms";
import ChronicCareManagement from "./Pages/ChronicCareManagement";
import RemotePatientMonitoring from "./Pages/RemotePatientMonitoring";

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={`/`} component={Home} />
        <Route exact path={`/about`} component={About} />
        <Route exact path={`/product`} component={Product} />
        <Route exact path={`/providers`} component={Providers} />
        <Route exact path={`/payors`} component={Payors} />
        <Route exact path={`/contact`} component={Contact} />
        <Route exact path={`/our-program`} component={OurPrograms} />
        <Route
          exact
          path={`/chronic-care-management`}
          component={ChronicCareManagement}
        />
        <Route
          exact
          path={`/remote-patient-monitoring`}
          component={RemotePatientMonitoring}
        />
      </Switch>
    </Router>
  );
}

export default App;
